import * as React from "react"
import { prismicGetText, extractPrismicNode, extractPrismicCollectionNode } from "../../../utils/prismicHelper"
import { TABLET_SCREEN_WIDTH } from "../../../utils/responsiveHelper"
import { RichText } from "prismic-reactjs"
import { _Blockquote } from "../../../components/shared/blockquote.styles"
import { AppStateContext } from "../../../utils/PageWrapper"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import styled from "styled-components"
import { GenericPanel, GenericItemProps } from "./GenericPanel"
import { filterPostsByCategory, prepareNewsPublications, prepareWebinars, prepareAggregatedGenericItems, prepareDataPosts } from "./utils"
import SearcHIcon from "../../../images/search.svg"
import queryString from "query-string"

const ReactTags = require("react-tag-autocomplete")
const _ = require("lodash")

import "./react-tag-autocomplete.css"

const DEFAULT_TAGS_PLACEHOLDER = "Search by tags  "

type Tabbable<T> = T & { tabsRole: string }

const STabs = styled(Tabs)``

const STabList: Tabbable<typeof TabList> = styled(TabList)`
    list-style-type: none;
    padding: 0;
    display: flex;
    margin: 0;
    border-bottom: 1px solid #979797;

    @media only screen and (max-width: ${TABLET_SCREEN_WIDTH}px) {
        overflow-x: scroll;
    }
` as any

STabList.tabsRole = "TabList"

const STab: Tabbable<typeof Tab> = styled(Tab)`
    margin-right: 0px;
    padding: 10px;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;

    font-family: Nunito;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: var(--warm-grey);

    &:first-child {
        padding-left: 0;
    }

    &:not(:first-child) {
        padding-left: 0.6em;
        padding-right: 0.6em;
    }

    @media only screen and (min-width: ${TABLET_SCREEN_WIDTH}px) {
        &:not(:first-child) {
            padding-left: 2em;
            padding-right: 2em;
        }
    }

    &.is-selected {
        color: var(--warm-blue);
        border-bottom: 2px solid var(--warm-blue);
        font-weight: bold;
    }

    &:focus {
        outline: none;
    }
` as any
STab.tabsRole = "Tab"

const STabPanel: Tabbable<typeof TabPanel> = styled(TabPanel)`
    display: none;

    &.is-selected {
        display: block;
    }
` as any
STabPanel.tabsRole = "TabPanel"

interface InsightsSectionsProps {
    data: any
    posts: any
}

interface TagProps {
    name: string
}

const _tagSearchLi = styled.li`
    margin-left: auto;
    margin-top: 8px;
`

const _tagSearchContainer = styled.div`
    position: relative;
`

const _tagSearchMobileContainer = styled.div`
    position: relative;
    margin-top: 15px;
    margin-bottom: 0;
`

const _tagSearchIcon = styled.span`
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    left: 15px;

    width: 17px;
    height: 17px;

    img {
        width: 100%;
        height: 100%;
    }
`

interface ItemProps {
    node: {
        _meta: {
            tags: string[]
        }
    }
}

const filterItemsByTags = (items: ItemProps[], tags: TagProps[]) => {
    if (!tags) return items
    if (tags.length === 0) return items

    const tagNames = tags.reduce((accumulator: string[], currentValue: { name: string }) => {
        accumulator.push(currentValue.name)
        return accumulator
    }, [])

    const filteredItems = items.filter((item: { node: { _meta: { tags: string[] } } }) => {
        const itemTags = item.node._meta.tags
        const found = tagNames.some((r: any) => itemTags.includes(r))

        return found
    })
    return filteredItems
}

export const InsightSections: React.FC<InsightsSectionsProps> = ({ data, posts }) => {
    const appState = React.useContext(AppStateContext)
    const [isReady, setIsReady] = React.useState<boolean>(false)
    const tagSearchComponent = React.useRef(null)

    const [searchTags, setSearchTags] = React.useState<TagProps[]>()
    const [blogPosts, setBlogPosts] = React.useState(posts)
    const [reactTagsPlaceholder, setReactTagsPlaceholder] = React.useState(DEFAULT_TAGS_PLACEHOLDER)

    //const innovatorsDoc = extractPrismicNode(data, "allInsight_innovatorss")
    const dataDoc = extractPrismicNode(data, "allInsight_datas")
    const disruptiveDoc = extractPrismicNode(data, "allInsight_disruptives")
    const insightsDoc = extractPrismicNode(data, "allInsight_insightss")
    const newsDoc = extractPrismicNode(data, "allInsight_newss")
    const eventsDoc = extractPrismicNode(data, "allEventss")

    const allNewsPublications = extractPrismicCollectionNode(data, "allNews_publicationss")
    const allWebinars = extractPrismicCollectionNode(data, "allWebinars")
    const doc = extractPrismicNode(data, "allInsight_blogs")

    const [webinars, setWebinars] = React.useState(allWebinars)
    const [newsPublications, setNewsPublications] = React.useState(allNewsPublications)

    const existingTags = _.compact(
        [...posts, ...allNewsPublications, ...allWebinars].flatMap((item: { node: { _meta: { tags: string[] } } }) => {
            if (item && item.node && item.node._meta && item.node._meta.tags) return item.node._meta.tags
        })
    )

    const existingTagSugestions = _.uniq(existingTags).map((tag: string) => ({ name: tag }))

    React.useEffect(() => {
        filterItems()
        const placeholder = searchTags && searchTags.length > 0 ? "" : DEFAULT_TAGS_PLACEHOLDER
        setReactTagsPlaceholder(placeholder)
    }, [searchTags])

    React.useEffect(() => {
        const parsedParams = queryString.parse(location.search)
        if (parsedParams["tag"]) {
            const tag = { name: parsedParams["tag"] }
            setSearchTags([tag] as TagProps[])
        }
    }, [isReady])

    React.useEffect(() => {
        if (typeof window !== `undefined`) {
            setIsReady(true)
        }
    })

    const handleTagAdd = (tag: TagProps) => {
        const tmpTags = searchTags || []
        tmpTags.push(tag)
        setSearchTags([...tmpTags])
    }

    const handleTagRemove = (tagIndex: number) => {
        if (!searchTags) return
        const tmpTags = searchTags.slice(0)
        tmpTags.splice(tagIndex, 1)
        setSearchTags([...tmpTags])
    }

    const filterItems = () => {
        setBlogPosts(filterItemsByTags(posts, searchTags!))
        setWebinars(filterItemsByTags(allWebinars, searchTags!))
        setNewsPublications(filterItemsByTags(allNewsPublications, searchTags!))
    }

    if (!data && !posts) return null
    if (!isReady) return null

    return (
        <>
            <STabs selectedTabClassName="is-selected" selectedTabPanelClassName="is-selected">
                <STabList>
                    <STab>All</STab>
                    <STab>{prismicGetText(doc.insight_tab3)}</STab>
                    <STab>{prismicGetText(doc.insight_tab4)}</STab>
                    {/*
                        "Breaking through" section is hidden
                        <STab>{prismicGetText(doc.insight_tab1)}</STab>
                    */}
                    <STab>Data</STab>
                    <STab>{prismicGetText(doc.insight_tab5)}</STab>
                    <STab>Disruptive voices</STab>

                    {!appState.isMobile && (
                        <_tagSearchLi>
                            <_tagSearchContainer>
                                <_tagSearchIcon>
                                    <img src={SearcHIcon} />
                                </_tagSearchIcon>
                                <ReactTags
                                    ref={tagSearchComponent}
                                    tags={searchTags}
                                    suggestions={existingTagSugestions}
                                    placeholderText={reactTagsPlaceholder}
                                    onDelete={handleTagRemove}
                                    allowNew
                                    onAddition={handleTagAdd}
                                    autoresize={false}
                                />
                            </_tagSearchContainer>
                        </_tagSearchLi>
                    )}
                </STabList>

                {appState.isMobile && (
                    <_tagSearchMobileContainer>
                        <_tagSearchIcon>
                            <img src={SearcHIcon} />
                        </_tagSearchIcon>
                        <ReactTags
                            ref={tagSearchComponent}
                            tags={searchTags}
                            suggestions={existingTagSugestions}
                            placeholderText={reactTagsPlaceholder}
                            onDelete={handleTagRemove}
                            allowNew
                            onAddition={handleTagAdd}
                            autoresize={false}
                        />
                    </_tagSearchMobileContainer>
                )}

                <STabPanel>
                    <GenericPanel
                        items={
                            (prepareAggregatedGenericItems({
                                newsPublications,
                                blogPosts,
                                webinars
                            }) as unknown) as GenericItemProps[]
                        }
                    />
                </STabPanel>

                <STabPanel>
                    <GenericPanel
                        lead={<RichText render={insightsDoc.description_insights} />}
                        items={filterPostsByCategory(blogPosts, "insights")}
                    />
                </STabPanel>

                <STabPanel>
                    <GenericPanel
                        lead={<RichText render={newsDoc.description_news} />}
                        items={prepareNewsPublications(newsPublications)}
                    />
                </STabPanel>

                {/*
                    "Breaking through" section is hidden
                    <STabPanel>
                    <GenericPanel
                        lead={<RichText render={innovatorsDoc.description_innovators} />}
                        items={filterPostsByCategory(blogPosts, "innovators")}
                    />
                    </STabPanel>
                 */}

                <STabPanel>
                    <GenericPanel
                        lead={<RichText render={dataDoc.description_data} />}
                        items={prepareDataPosts(blogPosts, newsPublications, "insight_data")}
                    />
                </STabPanel>

                <STabPanel>
                    <GenericPanel
                        lead={<RichText render={eventsDoc.description_events} />}
                        items={prepareWebinars(webinars)}
                    />
                </STabPanel>

                <STabPanel>
                    <GenericPanel
                        lead={<RichText render={disruptiveDoc.description_disruptive_voices} />}
                        items={filterPostsByCategory(blogPosts, "disruptive-voices")}
                    />
                </STabPanel>
            </STabs>
        </>
    )
}
