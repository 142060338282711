import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { validationSchema, onSubmit, MobileForm, initialValues } from "./insightSections/InsightNewsletterForm"
import { Formik } from "formik"
import { _Blockquote } from "../../components/shared/blockquote.styles"

export const InsightMobileNewsletterForm: React.FC = ({}) => {
    const appState = React.useContext(AppStateContext)

    if (!appState.isMobile) return null
    return (
        <>
            <Formik
                render={formikProps => <MobileForm {...formikProps} />}
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
            />

            <_Blockquote style={{ fontSize: "14px", textAlign: "center" }}>Subscribe to our newsletter</_Blockquote>
        </>
    )
}
