import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h1, _description } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { _linkButton, _linkBlockButton, _alternativeButton } from "../shared/buttons/"
import { Row } from "react-flexbox-grid"
import styled from "styled-components"
import closeSvg from "../../images/white_close.svg"
import { validationSchema, onSubmit, FlashForm, initialValues } from "./insightSections/InsightNewsletterForm"
import { Formik } from "formik"

const _FlashWrapper = styled.div`
    background-color: var(--warm-blue);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;

    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--white);
    padding: 2em 4em;

    p {
        margin: 0;
        color: var(--white);
    }
`

export const _EmailInput = styled.input.attrs(props => ({
    type: "text",
    size: props.size || "0.7em"
}))`
    border: none;
    font-size: 1em;
    border-radius: 4px;
    height: 48px;
    width: 300px;
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, var(--white), var(--white));
    margin: 0;
    margin-top: 0.5em;
    padding: ${props => props.size};

    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--slate-grey);
`

export const _formContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;

    input {
        margin-top: 0;
    }
`

const _flashLead = styled.div`
    flex: 1;
`

const _closeFlashButton = styled.button`
    background: none;
    border: none;
    color: white;
    margin-left: 2em;
`

const _flashFormButtonWrapper = styled.div`
    display: inline-flex;
`

export const InsightNewsletterFlash: React.FC = ({}) => {
    const appState = React.useContext(AppStateContext)
    const [flashIsOpen, setFlashIsOpen] = React.useState(true)
    const [isSubscribed, setIsSubscribed] = React.useState(false)

    if (!flashIsOpen || appState.isMobile) return null
    return (
        <_FlashWrapper>
            <Row middle="xs">
                <_flashLead>
                    <p>
                        {!isSubscribed ? "Subscribe to our newsletter" : "Great! You are subscribed to our newsletter!"}
                    </p>
                </_flashLead>

                <_flashFormButtonWrapper>
                    {!isSubscribed && (
                        <Formik
                            render={formikProps => <FlashForm {...formikProps} setIsSubscribed={setIsSubscribed} />}
                            validationSchema={validationSchema}
                            initialValues={initialValues}
                            onSubmit={onSubmit}
                        />
                    )}

                    <_closeFlashButton
                        onClick={event => {
                            event.preventDefault()
                            setFlashIsOpen(false)
                        }}>
                        <img src={closeSvg} />
                    </_closeFlashButton>
                </_flashFormButtonWrapper>
            </Row>
        </_FlashWrapper>
    )
}
