import * as React from "react"
import { _Blockquote } from "../../shared/blockquote.styles"
import { Col, Row } from "react-flexbox-grid"
import { BlogPostCard } from "../../../components/shared/cards"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Pagination } from "../../../utils"

interface GenericPanelProps {
    lead?: JSX.Element
    items: GenericItemProps[]
}

export interface GenericItemProps {
    type: string
    title: string
    date: string
    author?: string
    image: string
    source?: string
    link: {
        url?: string
        to?: string
        prevPath?: string
    }
    coverPhoto: string
    tags: string[]
    category: string
}

export const GenericPanel: React.FC<GenericPanelProps> = ({ lead, items }) => {
    const itemsPerPage = 9
    const pageCount = items.length / itemsPerPage
    const [offset, setOffset] = React.useState(0)

    const onPageChange = (data: any) => {
        scrollTo("#genericItems")
        setOffset(data.selected)
    }

    const paginationStart = offset * itemsPerPage
    const paginationEnd = paginationStart + itemsPerPage
    const paginatedItems = items.slice(paginationStart, paginationEnd)

    return (
        <>
            <Row id="genericItems">
                <Col lg={8}>
                    <_Blockquote>{lead}</_Blockquote>
                </Col>
            </Row>

            <Row>
                <Col lg={12}>
                    <Row style={{ marginTop: 2 + "em" }}>
                        {paginatedItems.map(item => {
                            return (
                                <Col lg={4} md={6} sm={12} key={item.title}>
                                    <BlogPostCard
                                        category={item.category}
                                        image={item.image || item.coverPhoto}
                                        date={item.date}
                                        title={item.title}
                                        author={item.author}
                                        tags={item.tags}
                                        link={item.link.to ? item.link : undefined}
                                        externalLink={
                                            item.link.url
                                                ? {
                                                      href: item.link.url
                                                  }
                                                : undefined
                                        }
                                        source={item.source}
                                    />
                                </Col>
                            )
                        })}
                    </Row>

                    {pageCount > 1 && (
                        <Row>
                            <Pagination pageCount={pageCount} onPageChange={onPageChange} />
                        </Row>
                    )}
                </Col>
            </Row>
        </>
    )
}
