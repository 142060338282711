import * as React from "react"
import { Layout, _Section, HeadComponent } from "../components/shared"
import { graphql } from "gatsby"
import { Hero } from "../components/productsPage/shared"
import { prismicGetText, extractPrismicNode, prismicGetRichHeader } from "../utils/prismicHelper"
import { PageWrapper } from "../utils/PageWrapper"
import { RichText } from "prismic-reactjs"
import { _h2 } from "../components/shared/headline.styles"
import { _Blockquote } from "../components/shared/blockquote.styles"
import { _externalLinkButton } from "../components/shared/buttons"
import { InsightSections } from "../components/blogPost/insightSections"
import InsightsImage from "../images/insights/insights-illustration.svg"
import { InsightNewsletterFlash } from "../components/blogPost/InsightNewsletterFlash"
import { InsightMobileNewsletterForm } from "../components/blogPost/InsightMobileNewsletterForm"
import { useReadyState, useForceUpdateState } from "../hooks"

export const prismicQuery = graphql`
    {
        prismic {
            ...insightsDataFragment
            ...insightsDisruptiveFragment
            ...insightsBlogsFragment
            ...insightsBlogPostsFragment
            ...insightsInnovatorsFragment
            ...insightsResearchersFragment
            ...insightsInsightsFragment
            ...insightsNewsFragment
            ...insightsResearchItemsFragment
            ...insightsNewsPublicationsFragment
            ...webinarsFragment
            ...insightsEventsFragment
        }
    }
`

interface InsightsPageProps {
    data: {
        prismic: {
            allInsight_blogs: {
                edges: any
            }
        }
    }

    pageContext: {
        posts: any
    }
}

const InsightsPage: React.FC<InsightsPageProps> = props => {
    const {
        data,
        pageContext: { posts }
    } = props

    // Required check for no data being returned
    const doc = extractPrismicNode(data, "allInsight_blogs")

    if (!doc) return null

    useReadyState()
    useForceUpdateState()

    return (
        <>
            <HeadComponent
                title="Insights | Radicle"
                keywords={prismicGetText(doc.seo_keywords_insights)}
                description={prismicGetText(doc.seo_description_insights)}
            />
            <PageWrapper>
                <Layout>
                    <_Section>
                        <Hero
                            description="Insights"
                            title={prismicGetRichHeader(doc.title_insights_v2)}
                            lead={<RichText render={doc.lead_insights} />}
                            image={InsightsImage}
                            imageClassName="smallerHeroImage"
                            heroImage={{
                                height: "300px",
                                isBlured: false,
                                maxImgHeight: "100%"
                            }}>
                            <InsightMobileNewsletterForm />
                        </Hero>
                    </_Section>

                    <_Section>
                        <InsightSections data={data} posts={posts} />
                    </_Section>

                    <InsightNewsletterFlash />
                </Layout>
            </PageWrapper>
        </>
    )
}

export default InsightsPage
