import * as React from "react"
import { RichText } from "prismic-reactjs"

import { prismicGetText, linkResolver } from "../../../utils/prismicHelper"
import { _Blockquote } from "../../../components/shared/blockquote.styles"
import { dateDecorator } from "../../../utils"
const _ = require("lodash")

const OBJECT_TYPES = {
    BLOG_POST: "blogPost",
    WEBINAR: "webinar",
    NEWS_PUBLICATION: "newsPublication",
    RESEARCH_ITEM: "researchItem"
}

interface PostProps {
    node: {
        blog_title: any
        blog_date: string
        blog_author: any
        blog_cover_photo: {
            url: string
        }
        _meta: {
            uid: string
            tags: string[]
        }
    }
}

interface ResearchItemProps {
    node: {
        title_research: any
        description_research: any
        date_research: any
        cta_label: any
        file_pdf: {
            url: string
        }
    }
}

interface NewsPublicationProps {
    node: {
        news_source: any
        link_to_publication: {
            url: string
        }

        title: any
        when_it_was_published: any
        cover_photo_news: {
            url: string
        }
    }
}

interface WebinarProps {
    node: {
        title_webinar: any
        webinar_date: any
        subtitle_webinar: any
        cover_photo_webinar: {
            url: string
        }
        _meta: {
            uid: string
        }
        speakers: SpeakerProps[]
    }
}

interface SpeakerProps {
    image_speaker: {
        url: string
    }
}

const getCategoryByType = (type?: string): string => {
    if (!type) return ''

    let category

    switch (type) {
        case "innovators":
            category = "Breaking Through"
            break
        case "insights":
            category = "From the Lab"
            break
        case "insights_data":
            category = "Data"
            break
        case "insights_disruptive":
            category = "Disruptive voices"
            break
        default:
            category = ""
    }
    return category
}

const normalizeBlogPost = (post: any, prevPath: string) => ({
    category: getCategoryByType(post.blogpost_category._meta?.uid),
    type: OBJECT_TYPES.BLOG_POST,
    title: prismicGetText(post.blog_title),
    date: dateDecorator(post.blog_date),
    author: prismicGetText(post.blog_author),
    image: post.blog_cover_photo.url,
    link: {
        to: linkResolver(post._meta),
        prevPath
    },
    tags: post._meta.tags
})

const normalizeResearchItem = (item: any) => {
    return {
        category: "Research Item",
        type: OBJECT_TYPES.RESEARCH_ITEM,
        title_research: prismicGetText(item.title_research),
        file_pdf: item.file_pdf,
        description_research: <RichText render={item.description_research} />,
        date_research: dateDecorator(item.date_research),
        cta_label: prismicGetText(item.cta_label)
    }
}

const normalizeNewsPublication = (item: any) => {
    return {
        category: "News",
        type: OBJECT_TYPES.NEWS_PUBLICATION,
        title: prismicGetText(item.title),
        date: dateDecorator(item.when_it_was_published),
        source: prismicGetText(item.news_source),
        link: {
            url: item.link_to_publication.url
        },
        coverPhoto: item.cover_photo_news.url
    }
}

const normalizeWebinar = (item: any) => {
    return {
        type: OBJECT_TYPES.WEBINAR,
        title: prismicGetText(item.title_webinar),
        date: dateDecorator(item.webinar_date),
        source: "Webinar",
        category: "Webinar",
        link: {
            to: linkResolver(item._meta)
        },
        coverPhoto:
            (item.cover_photo_webinar && item.cover_photo_webinar.url) ||
            (item.speakers && item.speakers[0].image_speaker && item.speakers[0].image_speaker.url)
    }
}

export const filterPostsByCategory = (posts: any, category_type: string) => {
    const filteredPosts = posts.filter((element: { node: { blogpost_category: { _meta: { uid: string } } } }) => {
        if (element.node.blogpost_category && element.node.blogpost_category._meta) {
            //console.log(element.node.blogpost_category._meta);
            return element.node.blogpost_category._meta.uid === category_type
        } else {
            return false
        }
    })

    let prevPath = ""
    if (typeof window !== `undefined`) {
        prevPath = location.pathname || ""
    }

    return filteredPosts.map((post: PostProps) => normalizeBlogPost(post.node, prevPath))
}

export const filterNewsPublicationsByCategory = (newsPublications: any, category_type: string) => {
    const filteredNews = newsPublications.filter((element: { node: { blog_post_category: { _meta: { uid: string } } } }) => {
        if (element.node.blog_post_category && element.node.blog_post_category._meta) {
            return element.node.blog_post_category._meta.uid === category_type
        } else {
            return false
        }
    })

    return filteredNews.map((newsItem: NewsPublicationProps) => normalizeNewsPublication(newsItem.node))
}

export const prepareResearchItems = (items: any) => {
    return items.map((item: ResearchItemProps) => normalizeResearchItem(item.node))
}

export const prepareNewsPublications = (items: any) => {
    return items.map((item: NewsPublicationProps) => normalizeNewsPublication(item.node))
}

export const prepareWebinars = (items: any) => {
    return items.map((item: WebinarProps) => normalizeWebinar(item.node))
}

export const prepareDataPosts = (posts: any, newsItems: any, category_type: string) => {
    return [
        ...filterPostsByCategory(posts, category_type),
        ...filterNewsPublicationsByCategory(newsItems, category_type)
    ];
}

export const prepareAggregatedGenericItems = ({
    newsPublications,
    blogPosts,
    webinars
}: {
    newsPublications: any
    blogPosts: any
    webinars: any
}) => {
    const tmpNews = newsPublications.map((newsPublicationObject: NewsPublicationProps) => {
        return {
            type: OBJECT_TYPES.NEWS_PUBLICATION,
            objectDate: newsPublicationObject.node.when_it_was_published,
            ...newsPublicationObject.node
        }
    })

    const tmpBlogPosts = blogPosts.map((blogPostObject: PostProps) => {
        return {
            type: OBJECT_TYPES.BLOG_POST,
            objectDate: blogPostObject.node.blog_date,
            ...blogPostObject.node
        }
    })

    const tmpWebinars = webinars.map((webinarObject: WebinarProps) => {
        return {
            type: OBJECT_TYPES.WEBINAR,
            objectDate: webinarObject.node.webinar_date,
            ...webinarObject.node
        }
    })

    let agregated = [...tmpNews, ...tmpBlogPosts, ...tmpWebinars]
    agregated = _.orderBy(agregated, ["objectDate"], ["desc"])

    let prevPath = ""
    if (typeof window !== `undefined`) {
        prevPath = location.pathname || ""
    }

    return agregated.map(genericItem => {
        switch (genericItem.type) {
            case OBJECT_TYPES.NEWS_PUBLICATION:
                return normalizeNewsPublication(genericItem)
            case OBJECT_TYPES.BLOG_POST:
                return normalizeBlogPost(genericItem, prevPath)
            case OBJECT_TYPES.WEBINAR:
                return normalizeWebinar(genericItem)
            default:
                break
        }
    })
}
